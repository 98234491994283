<script>
import httpClient from "@/services/http.service";

export default {
  name: "AttendanceWinterScienceSchool",
  data(){
    return{
      scheduleId: this.$route.params.id || 0,
      email: '',
      buttonLoading: false
    }
  },
  methods:{
    async registerWinterScienceSchool() {
      this.buttonLoading = true
      try {
        const {data, status} = await httpClient.post('winter_science_school/winter-visiting/visit', {email: this.email, schedule_id: this.scheduleId})
        if (status === 200) {
          if (data.error){
            this.$toast.add({severity:'error', summary:'Посещение', detail:'Данная почта не может быть использована для посещения!', life: 3000});
          } else {
            if (data.time_status == 1){
              if (data.status == 1){
                this.$toast.add({severity:'warn', summary:'Посещение', detail:'Вы уже отсканировали QR!', life: 3000});
              } else if (data.status == 0){
                this.$toast.add({severity:'info', summary:'Посещение', detail:'Вы успешно отсканировали QR!', life: 3000});
              }
            } else {
              this.$toast.add({severity:'warn', summary:'Посещение', detail:'Время скана QR уже прошло или не настало!', life: 3000});
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
      this.buttonLoading = false
    },
  },
  mounted() {
    console.log(this.$route)
  }
}
</script>

<template>
  <div class="container my-5">
    <h1 class="text-center my-3">Посещение</h1>
    <h5>Введите свою почту</h5>
    <div class="row">
      <div class="p-inputgroup my-3 col-md-5">
      <span class="p-inputgroup-addon">
          <i class="pi pi-at"></i>
      </span>
        <InputText v-model="email" type="email" placeholder="Username" />
      </div>
    </div>
    <Button class="p-button-primary" label="Зарегистрироваться" type="button"
            :loading="buttonLoading" @click="registerWinterScienceSchool"/>
  </div>
</template>

<style scoped>

</style>